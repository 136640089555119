import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type { Dict } from '@/types/types';
import type {
  AddEntryParams,
  AddEntryResponse,
  ApiClientEntriesResult,
  CopyPeriodParams, CopyPeriodResponse,
} from './ApiClientEntries.types';

export class ApiClientEntries extends ApiClientBase {
  async fetchApiToken() {
    return this.config.apiToken || super.fetchApiToken();
  }

  async deleteEntry(signal: AbortSignal, params) {
    let result: string | null = null;

    try {
      const response = await this.fetchData<string>({
        queryString: 'third_party/api/entries',
        method: 'DELETE',
        apiVersion: 0,
        body: params,
        signal,
      });

      if (response.status === 204 || response.status === 200) {
        result = 'ok';
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async deleteEntryTags(signal: AbortSignal, id, tags: number[], additionalParams) {
    let result: Dict<string> | null = null;
    try {
      const { data, status } = await this.fetchData<Dict<string>>({
        signal,
        queryString: `third_party/api/entries/${id}/tags`,
        method: 'DELETE',
        apiVersion: 0,
        body: { tags: tags.join(','), ...additionalParams },
      });

      result = data;

      if (status === 204 || status === 200) {
        return result;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async editEntry(signal: AbortSignal, params) {
    return await this.fetchData({
      queryString: 'third_party/api/entries',
      method: 'PUT',
      apiVersion: 0,
      body: params,
      signal,
    });
  }

  async addEntryTags(signal: AbortSignal, id, tags: number[], additionalParams) {
    let result: Dict<string> | null = null;

    try {
      const { data, status } = await this.fetchData<Dict<string>>({
        signal,
        queryString: `third_party/api/entries/${id}/tags`,
        method: 'PUT',
        apiVersion: 0,
        body: { tags: tags.join(','), ...additionalParams },
      });

      result = data;

      if (status === 204 || status === 200) {
        return result;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }

    throw new Error(result.message ?? `${result}`);
  }

  async getEntriesInvoice(body) {
    let result: ApiClientEntriesResult = [];

    try {
      const { data, ok } = await this.fetchData<ApiClientEntriesResult>({
        queryString: 'entries',
        method: 'POST',
        apiVersion: 1,
        body,
      });

      result = data;

      if (ok) {
        return result;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }

    throw new Error('No results for get entries invoice.');
  }

  async addEntry(signal: AbortSignal, params: AddEntryParams) {
    return await this.fetchData<AddEntryResponse>({
      queryString: 'third_party/api/entries',
      method: 'POST',
      apiVersion: 0,
      body: new URLSearchParams({ ...params, duration: params.duration.toString() }).toString(),
      signal,
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Charset: 'utf-8',
      },
    });
  }

  async copyPeriod({ userId, ...body }: CopyPeriodParams) {
    const response = await this.fetchData<CopyPeriodResponse>({
      queryString: `time-entries/user/${userId}/copyPeriod`,
      method: 'POST',
      body,
    });

    return response;
  }
}
