import { APIError } from '@/services/RestApiClientFactory/ApiErrorHandler/ApiError/ApiError';
import {
  ApiErrorTextHandler,
} from '@/services/RestApiClientFactory/ApiErrorHandler/ApiErrorTextHandler/ApiErrorTextHandler';
import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import { createApiStringFromObject } from '@/services/RestApiClientFactory/utils';
import type { GetPasswordRulesValidationParams, GetPasswordRulesValidationResponse, LoginParams } from './ApiClientAuth.types';

export class ApiClientAuth extends ApiClientBase {
  async fetchApiToken() {
    return this.config.apiToken || super.fetchApiToken();
  }

  async logout() {
    return await this.fetchData({
      queryString: 'auth/logout/0',
      apiVersion: 0,
    });
  }

  async login(params: LoginParams) {
    return await this.fetchData<string>({
      queryString: 'auth/login',
      apiVersion: 0,
      method: 'POST',
      body: params,
    });
  }

  async token(params: LoginParams) {
    const response = await this.fetchData<string>({
      queryString: 'auth/token',
      apiVersion: 0,
      method: 'POST',
      body: createApiStringFromObject(params),
      oldTextApi: true,
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (!response.ok) {
      throw new ApiErrorTextHandler('', {
        text: response.data,
        ok: response.ok,
      });
    }

    return response;
  }

  async getPasswordRulesValidation(params: GetPasswordRulesValidationParams, signal: AbortSignal = null) {
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });

    const { data, ok, status } = await this.fetchData<GetPasswordRulesValidationResponse>({
      queryString: `auth/validation/password/rules?${searchParams.toString()}`,
      apiVersion: 3,
      method: 'GET',
      signal,
      isAuthRequired: false,
    });

    if (!ok) {
      throw new APIError('Password rules validation failed', status);
    }

    return data;
  }
}
