import Cookie from 'js-cookie';

export abstract class CookieService {
  name: string;

  protected constructor(name: string) {
    this.name = name;
  }

  get() {
    return Cookie.get(this.name);
  }

  set(value: string, settings: Cookies.CookieAttributes) {
    Cookie.set(this.name, value, settings);
  }

  remove() {
    Cookie.remove(this.name);
  }
}
