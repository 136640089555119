import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '@/translations/en_EN.json';
import { LANGUAGE } from '@/types/types';
import { config } from '@/config';

function getTranslations() {
  const translations = {
    [LANGUAGE.EN]: { translation: enTranslation },
  };

  if (globalThis.translations) {
    const { locale, reactStrings } = globalThis.translations;
    translations[locale] = { translation: reactStrings };
  }

  return translations;
}

// TODO: Enable after the TypeScript errors have been fixed
// declare module 'i18next' {
//   interface CustomTypeOptions {
//     resources: {
//       translation: typeof enTranslation
//     }
//   }
// }

i18n
  .use(initReactI18next)
  .init({
    resources: getTranslations(),
    fallbackLng: LANGUAGE.EN,
    lng: config.language,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnEmptyString: false,
    react: {
      bindI18n: 'languageChanged bundleReplaced',
    },
  });

export default i18n;

if (import.meta.hot) {
  function replaceTranslations(lang: LANGUAGE, bundle: typeof enTranslation) {
    i18n.removeResourceBundle(lang, 'translation');
    i18n.addResourceBundle(lang, 'translation', bundle);
    i18n.emit('bundleReplaced');
  }

  document.addEventListener('reactTranslationsChanged', () => {
    const { locale, reactStrings } = globalThis.translations;
    replaceTranslations(locale, reactStrings);
  });

  import.meta.hot.accept('@/translations/en_EN.json', (newModule) => {
    replaceTranslations(LANGUAGE.EN, newModule.default);
  });
}
